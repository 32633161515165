import React from "react";
import "./NotFoundComponent.scss";

class InternalErrorComponent extends React.Component {
  render() {
    return (
      <div className="f404-page">
        <div className="f404-container">
          <div className="f404-content">
            <div className="f404">
              <div className="ocean">
                <img
                  src={require("../assets/images/500error.jpg")}
                  className="error500"
                  alt="error500"
                />
              </div>
              <div className="text">
                <div className="t1" />
                <div className="t2">
                  500 - { this.props.message}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InternalErrorComponent;
