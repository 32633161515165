import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  validateRequire,
  validateNumberRequire,
  validateRequireSetValue,
  validateRequireSetValueArray,
  validateRequireSetValueArrayMatrix,
  validateRequireSetValueMultipleChoiceMultiple,
  validateRequireSetValueMultipleChoiceOne,
} from '../Utility/RequireAndValidate';
import SingleLineTextComponent from '../Components/SingleLineTextComponent';
import MultipleLineTextComponent from '../Components/MultipleLineTextComponent';
import MultipleChoiceSingleLineTextComponent from '../Components/MultipleChoiceSingleLineTextComponent';
import {
  autoNextCheckQuestion,
  addAutoNextToStore,
  removeAutoNextToStore,
} from '../Utility/RequireAndValidateAutoNext';
import {
  SINGLELINETEXT,
  MULTIPLELINETEXT,
  DISPLAYMESSAGE,
  NUMBER,
  RANKING,
  RATING,
  RATINGSCALE,
  DROPDOWNLIST,
  NETPROMOTORSCORE,
  MULTIPLECHOICEONE,
  MULTIPLECHOICEMULTIPLE,
  MULTIPLECHOICESINGLELINETEXT,
  PAGE,
  PHONE_SINGLELINETEXT,
} from '../Utility/TypeValues';
import { setLanguages } from '../Actions/ConductAction';
import DisplayMessageComponent from '../Components/DisplayMessageComponent';
import DropdownListComponent from '../Components/DropdownListComponent';
import MultipleChoiceMultipleComponent from '../Components/MultipleChoiceMultipleComponent';
import MultipleChoiceOneComponent from '../Components/MultipleChoiceOneComponent';
import NetPromotorScoreComponent from '../Components/NetPromotorScoreComponent';
import NumericComponent from '../Components/NumericComponent';
import RankingComponent from '../Components/RankingComponent';
import RatingComponent from '../Components/RatingComponent';
import RatingScaleComponent from '../Components/RatingScaleComponent';
import GroupsContainer from './GroupsContainer';
import { setWrapper } from '../Actions/AnswerAction';
import Exp from '../Expression/expression';
import { GROUP, QUESTION } from '../Utility/TypeValues';
import { isNullOrUndefined } from '../Utility/index';
import RatingNumpadComponent from 'src/Components/RatingNumpadComponent';
import SingleLinePhoneComponent from 'src/Components/SingleLinePhoneComponent';

class NodeContainer extends Component {
  constructor(props) {
    super(props);
    this.getAnswer = this.getAnswer.bind(this);
  }
  componentDidMount() {}
  getAnswer = (elementKey, defaultValue) => {
    const { answers } = this.props;
    if (Object.keys(answers).indexOf(elementKey) >= 0) {
      return answers[elementKey];
    }

    return defaultValue;
  };
  nodeList() {
    const { questions, answers, conduct } = this.props;
    let newQuestions = questions;
    const self = this;
    let context = Exp.survey_conduct_context(
      newQuestions,
      answers,
      conduct.contextVariables,
    );
    // Remove Answer and progress
    return this.props.nodes.map((nodeSurveyFlow, indexKey) => {
      if (nodeSurveyFlow.type === QUESTION) {
        let nodeQuestion = newQuestions.filter((a, b) => {
          return a.elementKey === nodeSurveyFlow.elementKey;
        });
        if (!nodeQuestion[0]) {
          throw new Error('Not found question = ' + nodeSurveyFlow.elementKey);
        }
        return self.pushComponentsToArray(
          nodeSurveyFlow,
          nodeQuestion[0],
          answers,
          newQuestions,
          indexKey,
          context,
        );
      } else if (nodeSurveyFlow.type === GROUP) {
        return (
          <GroupsContainer
            key={'groupscontainer' + nodeSurveyFlow.elementKey}
            nodes={nodeSurveyFlow}
            showButton={false}
          />
        );
      }
      return '';
    });
  }
  finParentPage(data) {
    if (isNullOrUndefined(data)) return false;
    const { surveyFlowArrayLine } = this.props.conduct;
    if (data.type === PAGE) {
      return data;
    }
    const flow = surveyFlowArrayLine.find((x) => x.id === data.parentID);
    if (isNullOrUndefined(flow)) return false;
    return this.finParentPage(flow);
  }
  keepStateAutoNext(nodeSurveyFlow, node) {
    const pageParentQuestions = this.finParentPage(nodeSurveyFlow);
    if (pageParentQuestions === false) return false;
    addAutoNextToStore(pageParentQuestions.elementKey, node.elementKey);
    return true;
  }
  removeKeepStateAutoNext(nodeSurveyFlow, keepStateAutoNext) {
    const pageParentQuestions = this.finParentPage(nodeSurveyFlow);
    removeAutoNextToStore(pageParentQuestions.elementKey, keepStateAutoNext);
  }
  pushComponentsToArray(
    nodeSurveyFlow,
    node,
    answers,
    questions,
    indexKey,
    context,
  ) {
    let publishKey = this.props.conduct.key;
    let keepQuestionElement = node.elementKey;
    // function check condition to show hide
    let elementKey = node.elementKey + nodeSurveyFlow.id;
    if (nodeSurveyFlow && context) {
      if (!Exp.run_object_context(nodeSurveyFlow.condition, context)) {
        //Remove
        this.removeKeepStateAutoNext(nodeSurveyFlow, keepQuestionElement);
        return '';
      }
    }

    this.keepStateAutoNext(nodeSurveyFlow, node);
    if (node.type === SINGLELINETEXT) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <SingleLineTextComponent
          key={SINGLELINETEXT + elementKey}
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByElement}
        />
      );
    } else if (node.type === PHONE_SINGLELINETEXT) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <SingleLinePhoneComponent
          key={PHONE_SINGLELINETEXT + elementKey}
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByElement}
        />
      );
    } else if (node.type === MULTIPLELINETEXT) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <MultipleLineTextComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByElement}
          key={MULTIPLELINETEXT + elementKey}
        />
      );
    } else if (node.type === DISPLAYMESSAGE) {
      return (
        <DisplayMessageComponent
          question={node}
          key={DISPLAYMESSAGE + elementKey}
        />
      );
    } else if (node.type === NUMBER) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <NumericComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerNumberByElement}
          key={NUMBER + elementKey}
        />
      );
    } else if (node.type === RANKING) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <RankingComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValueArray}
          key={RANKING + elementKey}
        />
      );
    } else if (node.type === RATING) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <RatingComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValue}
          key={RATING + elementKey}
        />
      );
    } else if (node.type === RATINGSCALE) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <RatingScaleComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValue}
          key={RATINGSCALE + elementKey}
        />
      );
    } else if (node.type === DROPDOWNLIST) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });
      return (
        <DropdownListComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValue}
          key={DROPDOWNLIST + elementKey}
          setLanguages={this.props.setLanguages}
          publishKey={publishKey}
        />
      );
    } else if (node.type === NETPROMOTORSCORE) {
      const answer = this.getAnswer(node.elementKey, { value: '', error: '' });

      const alias_type = 'alias_numpad';

      return alias_type && alias_type === 'alias_numpad' ? (
        <RatingNumpadComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValue}
          key={RATINGSCALE + elementKey}
        />
      ) : (
        <NetPromotorScoreComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValue}
          key={NETPROMOTORSCORE + elementKey}
        />
      );
    } else if (node.type === MULTIPLECHOICEONE) {
      let answer = {};
      if (node.includeOther) {
        answer = this.getAnswer(node.elementKey, {
          isIncludeOther: false,
          IncludeOtherText: '',
          value: [],
          error: '',
        });
      } else {
        answer = this.getAnswer(node.elementKey, {
          value: [],
          error: '',
        });
      }
      return (
        <MultipleChoiceOneComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValueArrayOneChoice}
          key={MULTIPLECHOICEONE + elementKey}
        />
      );
    } else if (node.type === MULTIPLECHOICESINGLELINETEXT) {
      let answer = this.getAnswer(node.elementKey, {
        isIncludeOther: false,
        IncludeOtherText: '',
        value: [],
        error: '',
      });

      return (
        <MultipleChoiceSingleLineTextComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValueArrayMutiple}
          key={MULTIPLECHOICESINGLELINETEXT + elementKey}
        />
      );
    } else if (node.type === MULTIPLECHOICEMULTIPLE) {
      let answer = {};
      if (node.includeOther) {
        answer = this.getAnswer(node.elementKey, {
          isIncludeOther: false,
          IncludeOtherText: '',
          value: [],
          error: '',
        });
      } else {
        answer = this.getAnswer(node.elementKey, {
          value: [],
          error: '',
        });
      }
      return (
        <MultipleChoiceMultipleComponent
          question={node}
          answer={answer}
          setAnswer={this.props.setAnswerByValueArrayMutiple}
          key={MULTIPLECHOICEMULTIPLE + elementKey}
        />
      );
    }
  }

  render() {
    return this.nodeList();
  }
}

const mapStateToProps = (state) => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data,
    questions: state.conductReducer.questions
      ? state.conductReducer.questions
      : [],
    page: state.conductReducer.page,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAnswerByValue: (props, value) => {
      validateRequireSetValue(props, value, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerByElement: (props, event) => {
      validateRequire(props, event, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerNumberByElement: (props, event) => {
      validateNumberRequire(props, event, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerByValueArray: (props, value) => {
      validateRequireSetValueArray(props, value, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerByValueArrayOneChoice: (props, value) => {
      validateRequireSetValueMultipleChoiceOne(props, value, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerByValueArrayMutiple: (props, value) => {
      validateRequireSetValueMultipleChoiceMultiple(props, value, dispatch);
      autoNextCheckQuestion();
    },
    setAnswerByValueArrayMatrix: (props, value, singleLine = false) => {
      validateRequireSetValueArrayMatrix(props, value, dispatch, singleLine);
      autoNextCheckQuestion();
    },
    setAnswerEmpty: (key, data) => {
      dispatch(setWrapper(key, data));
      autoNextCheckQuestion();
    },
    setLanguages: (key, languageID) => {
      let path = 'survey-language';
      dispatch(
        setLanguages(path + `?key=${key}&type=publish&lang=${languageID}`),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NodeContainer);
