import {
  ANSWER_SET_VALUE,
  ANSWER_SAVE_RESPONSE,
  ANSWER_START_RESPONSE,
  ANSWER_FINISH_RESPONSE,
  ANSWER_REMOVE,
  ANSWER_REMOVE_VALUE
} from "../ActionTypes/AnswerType";
import {
  saveStartConduct,
  saveFinishConduct,
  saveSurveyResponse
} from "../Services/ConductApi";
import {
  SINGLELINETEXT,
  MULTIPLELINETEXT,
  NUMBER,
  EMAIL_SINGLELINETEXT,
  DATETIME,
  DROPDOWNLIST,
  NETPROMOTORSCORE,
  RATING,
  RATINGSCALE,
  MULTIPLECHOICEONE,
  MULTIPLECHOICEMULTIPLE,
  RANKING,
  MATRIXRANKING,
  MATRIXCHOICEONE,
  MATRIXCHOICEMULTIPLE,
  MATRIXSINGLELINETEXT,
  QUESTION,
  MATRIXDROPDOWN,
  PHONE_SINGLELINETEXT
  // GROUP,
  // PAGE
} from "../Utility/TypeValues";
import {
  SINGLELINE_RESPONSE,
  MULTIPLELINE_RESPONSE,
  NUMERIC_RESPONSE,
  EMAIL_RESPONSE,
  DATETIME_RESPONSE,
  DROPDOWNLIST_RESPONSE,
  NPS_RESPONSE,
  RATING_RESPONSE,
  RATINGSCALE_RESPONSE,
  MULTIPLECHOICE_ONEANSWER_RESPONSE,
  MULTIPLECHOICE_MULTIPLEANSWER_RESPONSE,
  RANKING_RESPONSE,
  MATRIXRANKING_ANSWER_RESPONSE,
  MATRIXCHOICE_ONEANSWER_RESPONSE,
  MATRIXCHOICE_MULTIPLEANSWER_RESPONSE,
  MATRIXSINGLELINE_ANSWER_RESPONSE,
  MULTIPLECHOICE_SIGLELINETEXTANSWER_RESPONSE,
  MATRIX_DROPDOWN_RESPONSE,
  PHONE_RESPONSE
} from "../Utility/TypeValues";
import {
  isNullOrUndefined
} from "../Utility";
import {
  validateConditionToRoot,
  removeAnswerValueAny
} from "../Utility/ConditionValidator";
import Exp from "../Expression/expression";
import {
  AnswerSkip,
  setEmptyAnswer
} from "../Utility/AnswerValidateState";

export const setWrapper = (key, data) => {
  return {
    type: ANSWER_SET_VALUE,
    data: data,
    key: key
  };
  // async payload() {
  //   return {
  //     data: data,
  //     key: key
  //   };
  // }
};
export const removeAnswerValue = (list) => {
  return {
    type: ANSWER_REMOVE_VALUE,
    list: list
  }
}
export const removeAnswer = (key) => {
  return {
    type: ANSWER_REMOVE,
    key: key
  }
}
export const saveStart = (accessToken, conductID, token, callBack) => {
  return {
    type: ANSWER_START_RESPONSE,
   // async
   payload() {
      return saveStartConduct(accessToken, conductID, token, callBack);
    }
  };
};

export const saveFinish = (accessToken, conductID, token, callBack) => {
  return {
    type: ANSWER_FINISH_RESPONSE,
    //async
    payload() {
      return saveFinishConduct(accessToken, conductID, token, callBack);
    }
  };
};
const findAnswerResponse = (
  id,
  token,
  isPublish,
  elementKey, //questionElementKey
  answer,
  questionType,
  conductID
  // surveyFlow
) => {
  // let elementKey = question.elementKey;

  if (isNullOrUndefined(answer)) return null;
  let responseData = {
    conductID,
    elementKey
  };
  // construct response value
  if (answer) {
    switch (questionType) {
      case SINGLELINETEXT:
        responseData.responseType = SINGLELINE_RESPONSE;
        responseData.singleLineValue = answer.value;
        break;
      case MULTIPLELINETEXT:
        responseData.responseType = MULTIPLELINE_RESPONSE;
        responseData.multipleLineValue = answer.value;
        break;
      case NUMBER:
        responseData.responseType = NUMERIC_RESPONSE;
        responseData.numericValue = answer.value;
        break;
      case EMAIL_SINGLELINETEXT:
        responseData.responseType = EMAIL_RESPONSE;
        responseData.emailValue = answer.value;
        break;
      case DATETIME:
        responseData.responseType = DATETIME_RESPONSE;
        responseData.dateTimeValue = answer.value;
        break;
      case DROPDOWNLIST:
        responseData.responseType = DROPDOWNLIST_RESPONSE;
        responseData.optionSelected = answer.value;
        break;
      case NETPROMOTORSCORE:
        responseData.responseType = NPS_RESPONSE;
        responseData.netPromoterScore = answer.value;
        break;
      case RATING:
        responseData.responseType = RATING_RESPONSE;
        responseData.ratingIndex = answer.value;
        break;
      case RATINGSCALE:
        responseData.responseType = RATINGSCALE_RESPONSE;
        responseData.ratingScaleValue = answer.value;
        break;
      case MULTIPLECHOICEONE:
        if (answer.hasOwnProperty("isIncludeOther")) {
          responseData.includeOther = answer.isIncludeOther;
          responseData.otherTextValue = "";
        }
        if (answer.hasOwnProperty("IncludeOtherText")) {
          responseData.otherTextValue = answer.IncludeOtherText;
        }

        responseData.responseType = MULTIPLECHOICE_ONEANSWER_RESPONSE;
        responseData.multipleChoiceOneAnswerChoices = answer.value;
        break;
      case MULTIPLECHOICEMULTIPLE:
        if (answer.hasOwnProperty("isIncludeOther")) {
          responseData.includeOther = answer.isIncludeOther;
          responseData.otherTextValue = "";
        }
        if (answer.hasOwnProperty("IncludeOtherText")) {
          responseData.otherTextValue = answer.IncludeOtherText;
        }

        responseData.responseType = MULTIPLECHOICE_MULTIPLEANSWER_RESPONSE;
        responseData.multipleChoiceMultipleAnswerChoices = answer.value;
        break;
      case MULTIPLECHOICE_SIGLELINETEXTANSWER_RESPONSE:
        responseData.responseType = MULTIPLECHOICE_MULTIPLEANSWER_RESPONSE;
        responseData.multipleChoiceMultipleAnswerChoices = answer.value;
        break;
      case RANKING:
        responseData.responseType = RANKING_RESPONSE;
        responseData.rankingAnswers = answer.value;
        break;
      case MATRIXRANKING:
        responseData.responseType = MATRIXRANKING_ANSWER_RESPONSE;
        responseData.matrixRankingAnswers = answer.value;
        break;
      case MATRIXCHOICEONE:
        responseData.responseType = MATRIXCHOICE_ONEANSWER_RESPONSE;
        responseData.matrixChoiceOneAnswers = answer.value;
        break;
      case MATRIXCHOICEMULTIPLE:
        responseData.responseType = MATRIXCHOICE_MULTIPLEANSWER_RESPONSE;
        responseData.matrixChoiceMultipleAnswers = answer.value;
        break;
      case MATRIXDROPDOWN:
        responseData.responseType = MATRIX_DROPDOWN_RESPONSE;
        responseData.matrixDropdownAnswers = answer.value;
        break;

      case MATRIXSINGLELINETEXT:
        responseData.responseType = MATRIXSINGLELINE_ANSWER_RESPONSE;
        responseData.matrixSingleLineAnswers = answer.value;
        break;
      case PHONE_SINGLELINETEXT:
        responseData.responseType = PHONE_RESPONSE;
        responseData.phoneValue = answer.value;
        break;
      default:
        break;
    }
    return responseData;
  }
  return null;
};
const recRoot = (roots, questions, context, currentNodeList) => {
  if (roots.type === QUESTION) {
    if (validateConditionToRoot(roots, context)) {
      currentNodeList.push(roots.elementKey);
    }
  } else {
    roots.children.forEach(surveyFlowChild => {
      if (surveyFlowChild.type === QUESTION) {
        if (validateConditionToRoot(surveyFlowChild, context)) {
          currentNodeList.push(surveyFlowChild.elementKey);
        }
      } else {
        //Group Page
        if (!isNullOrUndefined(surveyFlowChild.children)) {
          if (validateConditionToRoot(surveyFlowChild, context)) {
            recRoot(surveyFlowChild, questions, context, currentNodeList);
          }
        }
      }
    });
  }
};
export const saveResponse = (props, callBack = () => {}) => {
  const {
    id,
    token,
    isPublish,
    questions,
    conductID,
    currentNode,
    contextVariables,
    accessToken
  } = props.conduct;
  const {
    answers
  } = props;
  if (isNullOrUndefined(answers)) return;

  const context = Exp.survey_conduct_context(
    questions,
    answers,
    contextVariables
  );
  let currentNodeList = [];
  recRoot(currentNode, questions, context, currentNodeList);
  let questionType = questions.reduce((acc, v, iarr) => {
    acc[v.elementKey] = v.type;
    return acc;
  }, {});

  const answerKeys = Object.keys(answers);
  let collection = [];
  collection = answerKeys.reduce((acc, elementKey, i, arr) => {
    if (
      !isNullOrUndefined(elementKey) &&
      currentNodeList.indexOf(elementKey) >= 0
    ) {
      let data = findAnswerResponse(
        id,
        token,
        isPublish,
        elementKey,
        answers[elementKey],
        questionType[elementKey],
        conductID
      );
      if (!isNullOrUndefined(data)) acc.push(data);
    }

    return acc;
  }, []);
  // set empty answer if  keepAnswerState === false and not pass surveyflow
  if (AnswerSkip.keepAnswerState === false) {
    AnswerSkip.value.forEach(elementKey => {
      let q = questions.filter(e => e.elementKey === elementKey)[0];
      if (q) {
        let answer = setEmptyAnswer(q, {});
        let dataSkip = findAnswerResponse(
          id,
          token,
          isPublish,
          elementKey,
          answer,
          questionType[elementKey],
          conductID);
        collection.push(dataSkip);
      }
    });
    AnswerSkip.clearValue();
  }
  const removeElementKey =  removeAnswerValueAny();
  collection = collection.filter(x => {
    return removeElementKey.indexOf(x.elementKey) < 0;
  });

  // end set empty answer if  keepAnswerState === false and not pass surveyflow
  // if (collection.length === 0) {
  //   return {};
  // }
    props.getDispatch()({
      type: ANSWER_SAVE_RESPONSE,
      payload() {
        return  saveSurveyResponse(accessToken, token, collection, callBack);
      }
    });
  return {};
};
