import React from "react";
import parse from 'html-react-parser';
import {convertTextToScreen} from "../Utility/ConditionValidator";

class DisplayMessageComponent extends React.Component {
  render() {
    const { displayMessageContent } = this.props.question;
    if (displayMessageContent) {
      return (
        <div id={`${this.props.question.elementKey}`} className={`question message`}>
          <div className="question_header">
            {!displayMessageContent.headerText ? null : (
              <div className="question_title"><span style={{wordWrap: 'break-word'}}>{parse(convertTextToScreen(displayMessageContent.headerText))}</span></div>
            )}
            <div className="question_description" style={{wordWrap: 'break-word'}}>
              {!displayMessageContent.descriptionRichText
                ? null
                : parse(convertTextToScreen(displayMessageContent.descriptionRichText))}
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

export default DisplayMessageComponent;
