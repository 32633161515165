import {
  CONDUCT_GET_DATA,
  CONDUCT_GET_QUESTION,
  CONDUCT_SET_PAGE,
  CONDUCT_SET_LANGUAGES,
  CONDUCT_SET__SURVEYCONTENT,
  CONDUCT_SET_NODE,
  CONDUCT_SET_CURRENT_NODE,
  CONDUCT_SET_PAGECHANGE,
  CONDUCT_SET_CHANNEL,
} from '../ActionTypes/ConductType';
import { getConductData, getConductQuestion } from '../Services/ConductApi';
import { INDEX } from '../Utility/TypeValues';
export const setCurrentNode = (node) => {
  return {
    type: CONDUCT_SET_CURRENT_NODE,
    currentNode: node,
  };
};
export const selectConductData = (path, token) => {
  return {
    type: CONDUCT_GET_DATA,
    async payload() {
      return await getConductData(path, token);
      //return json;
    },
  };
};
export const selectConductQuestion = (id) => {
  return {
    type: CONDUCT_GET_QUESTION,
    async payload() {
      return getConductQuestion(id);
    },
  };
};
export const setIndexPage = () => {
  return {
    type: CONDUCT_SET_PAGE,
    page: INDEX,
  };
};
export const setConductNode = (node) => {
  return {
    type: CONDUCT_SET_NODE,
    node: node,
  };
};
export const setPageChange = (pageChage, changeState) => {
  return {
    type: CONDUCT_SET_PAGECHANGE,
    pageChange: pageChage,
    changeState: changeState,
  };
};

export const setLanguages = (path, token) => {
  return {
    type: CONDUCT_SET_LANGUAGES,
    async payload() {
      return await getConductData(path, token);
    },
  };
};
export const setsurveyContent = (surveyContent) => {
  return {
    type: CONDUCT_SET__SURVEYCONTENT,
    surveyContent: surveyContent,
  };
};
export const setConductChannel = (data) => {
  return {
    type: CONDUCT_SET_CHANNEL,
    channel: data,
  };
};
