import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setConductNode, setCurrentNode } from '../Actions/ConductAction';
import {
  THANKYOU,
  ERROR_500,
  NOTFOUND,
  LOADING,
  WELCOME,
  GROUP,
  PAGE,
  QUESTION,
} from '../Utility/TypeValues';
import { dataPassValidate } from '../Utility/RequireAndValidate';
import {
  saveResponse,
  saveFinish,
  setWrapper,
  saveStart,
} from '../Actions/AnswerAction';
import {
  getStackAction,
  getLengthStackAction,
  getCopySurveyFlow,
  setStackAction,
  validateConditionToRoot,
  countProgressByPage,
} from '../Utility/ConditionValidator';
import Exp from '../Expression/expression';
import { isNullOrUndefined } from '../Utility/index';
import $ from 'jquery-slim';
import { parseUrl } from '../Utility/index';
import { ActionSubscriptionService } from '../Services/ActionSubscriptionService';
import parse from 'html-react-parser';

class ButtonContainer extends Component {
  componentDidMount() {
    const { skipWelcomePage, currentNode } = this.props.conduct;
    this.surveyFlow = getCopySurveyFlow();
    if (skipWelcomePage) {
      let stackAction = getStackAction();
      if (currentNode && stackAction.length === 0) {
        countProgressByPage(1);
        setStackAction(false, currentNode);
      }
    }
    ActionSubscriptionService.getSubject().subscribe((isClick) => {
      if (isClick) {
        this.clickNext();
      }
    });
  }
  clickBack() {
    const {
      //  node,
      questions,
      contextVariables,
      currentNode,
    } = this.props.conduct;
    const { answers } = this.props;
    let stackAction = getStackAction();
    countProgressByPage(-1);
    if (stackAction.length > 0) {
      let count = getLengthStackAction(1);
      let rootPrevious = stackAction[count];
      //Pop stack if top stack is current Node
      if (currentNode.id === rootPrevious.id) {
        setStackAction(true);
        count = getLengthStackAction(1);
        rootPrevious = stackAction[count];
      }
      const context = Exp.survey_conduct_context(
        questions,
        answers,
        contextVariables,
      );
      //Validate To Root
      while (rootPrevious && !validateConditionToRoot(rootPrevious, context)) {
        count--;
        if (stackAction.indexOf(count) >= 0) {
          rootPrevious = stackAction[count];
          setStackAction(true);
        }
      }
      if (rootPrevious) {
        if (getLengthStackAction(0) > 0) {
          setStackAction(true);
        }
        this.props.setCurrentNode(rootPrevious);
      }
      setTimeout(() => {
        $('.inner').scrollTop(0);
      }, 10);
    }
  }

  emojiToUnicode(emoji) {
    const codePoints = [];
    for (let i = 0; i < emoji.length; i++) {
      const charCode = emoji.charCodeAt(i);
      if ((charCode & 0xfc00) === 0xd800 && i + 1 < emoji.length) {
        // This is a high surrogate and there's a following low surrogate
        const nextCharCode = emoji.charCodeAt(i + 1);
        if ((nextCharCode & 0xfc00) === 0xdc00) {
          // Valid surrogate pair
          const codePoint =
            (((charCode & 0x3ff) << 10) | (nextCharCode & 0x3ff)) + 0x10000;
          codePoints.push(codePoint);
          i++; // Skip the low surrogate
        } else {
          // Invalid surrogate pair, treat the high surrogate as a standalone character
          codePoints.push(charCode);
        }
      } else {
        codePoints.push(charCode);
      }
    }
    const unicodeCodePoints = codePoints.map(
      (cp) => `0${cp.toString(16).toUpperCase()}`,
    );
    return unicodeCodePoints.join(' ');
  }

  detectEmoji(inputString) {
    const emojiRegex = require('emoji-regex');
    const regex = emojiRegex();

    const matches = inputString.match(regex);

    if (!matches) {
      return inputString; // No emojis found, return the original string.
    }

    let outputString = inputString;

    matches.forEach((match) => {
      //  change to Unicode code point เต็ม ex. U+1F44D
      const unicode = Array.from(match).map((char) =>
        this.emojiToUnicode(char),
      );
      console.log('unicode', unicode);
      // replace emoji with unicode
      outputString = outputString.replace(match, unicode);
    });

    return outputString;
  }

  clickNext() {
    const { node, questions, contextVariables } = this.props.conduct;
    const { answers } = this.props;

    for (let key in answers) {
      if (answers.hasOwnProperty(key)) {
        if (typeof answers[key].value === 'string') {
          answers[key].value = this.detectEmoji(answers[key].value);
        }
      }
    }

    const context = Exp.survey_conduct_context(
      questions,
      answers,
      contextVariables,
    );

    if (!dataPassValidate(this.props, this.props.getDispatch())) {
      setTimeout(() => {
        if (document.getElementsByClassName('error_message')[0]) {
          let top =
            document.getElementsByClassName('error_message')[0].offsetTop;

          $('.inner').scrollTop(top < 150 ? 50 : top - 50);
        }
      }, 80);

      return;
    }
    countProgressByPage(1);
    let valueObj = getCopySurveyFlow();
    let keyObj = valueObj.map((e) => e.id);

    let cNode = this.props.conduct.currentNode;
    let currentArrPostion = keyObj.indexOf(cNode.id) + 1;
    cNode = valueObj[currentArrPostion];
    if (node === WELCOME) {
      this.saveStartWelcome();
    }
    let goToThankyou = true;
    if (isNullOrUndefined(cNode)) {
      this.setThankyouPage();
      goToThankyou = false;
    }

    let nodeReturn = this.findNodesRender(
      cNode,
      questions,
      answers,
      context,
      keyObj,
      valueObj,
    );
    if (isNullOrUndefined(nodeReturn)) {
      if (goToThankyou) this.setThankyouPage();
    } else {
      this.props.setConductNode(
        { subRoot: nodeReturn.subRoot, childID: nodeReturn.id },
        this.props,
      );
      this.props.setCurrentNode(nodeReturn);
      this.saveResponseData();
      setTimeout(() => {
        $('.inner').scrollTop(0);
      }, 10);
    }
  }

  findNodesRender(nodeCurrent, questions, answers, context, keyObj, valueObj) {
    if (isNullOrUndefined(nodeCurrent)) return null;
    if (nodeCurrent.type === QUESTION) {
      let currentArrPostion = keyObj.indexOf(nodeCurrent.id);
      while (nodeCurrent && !validateConditionToRoot(nodeCurrent, context)) {
        currentArrPostion++;
        nodeCurrent = valueObj[currentArrPostion];
      }

      if (isNullOrUndefined(nodeCurrent)) {
        return null;
      } else if (nodeCurrent.type !== QUESTION) {
        return this.findNodesRender(
          nodeCurrent,
          questions,
          answers,
          context,
          keyObj,
          valueObj,
        );
      }
    } else if (nodeCurrent.type === PAGE) {
      let currentArrPostion = keyObj.indexOf(nodeCurrent.id);
      while (nodeCurrent && !validateConditionToRoot(nodeCurrent, context)) {
        currentArrPostion++;
        nodeCurrent = valueObj[currentArrPostion];
      }
      if (isNullOrUndefined(nodeCurrent)) {
        return null;
      } else if (nodeCurrent.type !== PAGE) {
        return this.findNodesRender(
          nodeCurrent,
          questions,
          answers,
          context,
          keyObj,
          valueObj,
        );
      }
    } else if (nodeCurrent.type === GROUP) {
      let currentArrPostion = keyObj.indexOf(nodeCurrent.id) + 1;
      nodeCurrent = valueObj[currentArrPostion];
      while (nodeCurrent && !validateConditionToRoot(nodeCurrent, context)) {
        currentArrPostion++;
        nodeCurrent = valueObj[currentArrPostion];
      }
      if (isNullOrUndefined(nodeCurrent)) {
        return null;
      }
      return this.findNodesRender(
        nodeCurrent,
        questions,
        answers,
        //  surveyFlow,
        context,
        keyObj,
        valueObj,
      );
    }
    return nodeCurrent;
  }
  saveResponseData() {
    const { isPublish } = this.props.conduct;
    if (isPublish) {
      this.props.saveResponse(this.props);
    }
  }
  saveStartWelcome() {
    const { isPublish, conductID, token, accessToken } = this.props.conduct;
    if (isPublish) {
      this.props.saveStart(accessToken, conductID, token);
    }
  }
  setThankyouPage() {
    const { token, isPublish, conductID, accessToken } = this.props.conduct;
    const self = this;
    if (isPublish) {
      $('.loadingP').remove();
      $('body').append(`<div class="loadingP" style="
            width: 100%;
            height: 100vh;
            background-color: rgba(21, 21, 21, 0.3);
            position: absolute;
            color: #5f5353;
            text-align: center;
            "><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve" style="
          width: 200px;
          height: 200px;
          margin-top: 10%;
      ">
            <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
              s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
              c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path>
            <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
              C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(150.479 20 20)">
              <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.5s" repeatCount="indefinite"></animateTransform>
              </path>
      </svg><div>Saving</div></div>`);
      const funcCallBack = (isSave) => {
        if (isSave) {
          // let logAnswers = self.props.apps;
          // if (logAnswers.length === 0) {
          self.props.saveFinish(accessToken, conductID, token, (ready) => {
            if (ready) {
              self.props.setCurrentNode(null);
              self.props.setConductNode(THANKYOU, this.props);
              $('.loadingP').remove();
              if (self.props.conduct.redirectThankyouPage) {
                window.location.href = parseUrl(
                  self.props.conduct.contextVariables,
                  self.props.conduct.redirectThankyouPageUrl,
                  self.props,
                );
              }
            } else {
              console.log('ไม่สามารถบันทึกข้อมูลได้ลองอีกครั้ง');
            }
          });

          // }
        } else {
          console.log('ไม่สามารถบันทึกข้อมูลได้ลองอีกครั้ง');
        }
      };
      self.props.saveResponse(this.props, funcCallBack);
    } else {
      self.props.setCurrentNode(null);
      self.props.setConductNode(THANKYOU, this.props);
    }
  }
  getViewRender() {
    const { node, surveyContent } = this.props.conduct;
    let stackAction = getStackAction();

    if (node === ERROR_500 || node === NOTFOUND || node === LOADING) {
      return '';
    }

    if (node === WELCOME) {
      return (
        <div className="survey_buttons">
          <button
            className="survey_button button_start"
            onClick={this.clickNext.bind(this)}
          >
            {parse(surveyContent.buttonStart)}
          </button>
        </div>
      );
    } else if (stackAction.length <= 1) {
      return (
        <div className="survey_buttons">
          <button
            className="survey_button button_next"
            onClick={this.clickNext.bind(this)}
          >
            {/* {parse(this.props.buttonNext)} */}
            {parse(surveyContent.buttonNext)}
          </button>
        </div>
      );
    } else if (
      stackAction.length > 1 &&
      stackAction.length <
        this.props.conduct.surveyFlow.children[
          this.props.conduct.surveyFlow.children.length - 1
        ].index
    ) {
      return (
        <div className="survey_buttons">
          <button
            className="survey_button button_previous"
            onClick={this.clickBack.bind(this)}
          >
            {/* {parse(this.props.buttonPrevious)} */}
            {parse(surveyContent.buttonPrevious)}
          </button>
          <button
            className="survey_button button_next"
            onClick={this.clickNext.bind(this)}
          >
            {/* {parse(this.props.buttonNext)} */}
            {parse(surveyContent.buttonNext)}
          </button>
        </div>
      );
    } else if (
      stackAction.length ===
      this.props.conduct.surveyFlow.children[
        this.props.conduct.surveyFlow.children.length - 1
      ].index
    ) {
      return (
        <div className="survey_buttons">
          <button
            className="survey_button button_previous"
            onClick={this.clickBack.bind(this)}
          >
            {/* {parse(this.props.buttonPrevious)} */}
            {parse(surveyContent.buttonPrevious)}
          </button>
          <button
            className="survey_button button_next"
            onClick={this.clickNext.bind(this)}
          >
            {parse(surveyContent.buttonSubmit)}
          </button>
        </div>
      );
    } else if (node === THANKYOU) {
      return '';
    }
  }

  render() {
    return <div className={'page_nav'}>{this.getViewRender()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data,
    apps: state.appReducer.notsave,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConductNode: (node, props) => {
      dispatch(setConductNode(node));
    },
    setCurrentNode: (currentNode) => {
      dispatch(setCurrentNode(currentNode));
      if (!isNullOrUndefined(currentNode)) {
        setStackAction(false, currentNode);
      }
    },
    getDispatch: () => {
      return dispatch;
    },

    setAnswerEmpty: (key, data) => {
      dispatch(setWrapper(key, data));
    },
    saveStart: (accessToken, id, token) => {
      dispatch(saveStart(accessToken, id, token));
    },
    saveResponse: (props, callBack) => {
      const save = saveResponse(props, callBack);
      if (save.type) {
        dispatch(save);
      }
      return save;
    },
    saveFinish: (accessToken, conductID, token, callBack) => {
      dispatch(saveFinish(accessToken, conductID, token, callBack));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonContainer);
