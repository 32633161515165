export const CONDUCT_GET_DATA = 'CONDUCT_GET_DATA';
export const CONDUCT_GET_QUESTION = 'CONDUCT_GET_QUESTION';
export const CONDUCT_SET_PAGE = 'CONDUCT_SET_PAGE';
export const CONDUCT_SET_NODE = 'CONDUCT_SET_NODE';
export const CONDUCT_GET_DATA_FULFILLED = 'CONDUCT_GET_DATA_FULFILLED';
export const CONDUCT_GET_QUESTION_FULFILLED = 'CONDUCT_GET_QUESTION_FULFILLED';
export const CONDUCT_GET_DATA_REJECTED = 'CONDUCT_GET_DATA_REJECTED';
export const CONDUCT_GET_QUESTION_REJECTED = 'CONDUCT_GET_QUESTION_REJECTED';
export const CONDUCT_SET_LANGUAGES = 'CONDUCT_SET_LANGUAGES';
export const CONDUCT_SET_LANGUAGES_FULFILLED =
  'CONDUCT_SET_LANGUAGES_FULFILLED';
export const CONDUCT_SET__SURVEYCONTENT = 'CONDUCT_SET__SURVEYCONTENT';
export const CONDUCT_SET_STACK_ACTION = 'CONDUCT_SET_STACK_ACTION';
export const CONDUCT_SET_CURRENT_NODE = 'CONDUCT_SET_CURRENT_NODE';
export const CONDUCT_SET_PREVIOUS_NODE = 'CONDUCT_SET_PREVIOUS_NODE';
export const CONDUCT_SET_PAGECHANGE = 'CONDUCT_SET_PAGECHANGE';
export const CONDUCT_SET_CHANNEL = 'CONDUCT_SET_CHANNEL';
