import React from "react";
import QuestionDetailsComponent from "./QuestionDetailsComponent";
import debounce from "lodash/debounce";
import parse from 'html-react-parser';
import { isNullOrUndefined } from "../Utility/index";

class MultipleChoiceSingleLineTextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = debounce(this.handleChange.bind(this), 100);
    this.getDataValue = this.getDataValue.bind(this);
  }
  findAnswer(ch) {
    const { answer } = this.props;
    let data = answer.value.find(e => {
      return e.elementKey === ch.elementKey;
    });
    return isNullOrUndefined(data) ? "" : data.choiceText;
  }
  currentAnswerValue() {
    /* define answer model */
    const { question } = this.props;
    let choice_values = question.choices.map(ch => ({
      elementKey: ch.elementKey,
      choiceText: this.findAnswer(ch),
      checkState: false
    }));
    let new_value = null;
    if (question.includeOther) {
      new_value = {
        isIncludeOther: false,
        IncludeOtherText: this.findAnswer(question),
        value: choice_values
      };
    } else {
      new_value = choice_values;
    }
    return new_value;
  }

  handleChange(value, choice) {
    const { answer } = this.props;
    let new_value = answer;
    new_value["value"] = this.currentAnswerValue();
    new_value.value = new_value.value.map(e => {
      if (e.elementKey === choice.elementKey) {
        e.choiceText = value;
      }
      return e;
    });
    this.props.setAnswer(this.props, new_value);
  }

  getDataValue(choice) {
    const { answer } = this.props;
    if (answer.value.length === 0) {
      return "";
    } else {
      return answer.value.reduce((acc, v, i, arr) => {
        if (v.elementKey === choice.elementKey) {
          acc = v.choiceText;
        }
        return acc;
      }, "");
    }
  }

  render() {
    const { question } = this.props;
    const { multipleChoiceDisplayLayout } = question;
    const choice_group_size = isNullOrUndefined(multipleChoiceDisplayLayout)
      ? 1
      : multipleChoiceDisplayLayout === "ONE_COLUMN"
        ? 1
        : multipleChoiceDisplayLayout === "TWO_COLUMN" ? 2 : 3;

    const choice_groups = question.choices.reduce(
      (acc, ch, i) => {
        let group = acc.pop();
        if (group.length === choice_group_size) {
          acc.push(group);
          group = [];
        }
        group.push(ch);
        acc.push(group);
        return acc;
      },
      [[]]
    );

    return (
      <div
        id={`${question.elementKey}`}
        className={`question multiple_choice_singlelinetext_answer`}
      >
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <div className="choices">
            {choice_groups.map(g => (
              <div
                key={g.reduce((acc, v, i, arr) => {
                  acc += v.elementKey;
                  return acc;
                }, "choice_group")}
                className="choice_group"
              >
                {g.map(ch => (
                  <div key={ch.elementKey} className="choiceinput">
                    {ch.choiceText ? (
                      <label
                        htmlFor={`choice${ch.elementKey}`}
                        className="choice_content"
                      >
                        {parse(ch.choiceText)}
                      </label>
                    ) : null}

                    <input
                      type="text"
                      id={`choice${ch.elementKey}`}
                      onKeyUp={e => {
                        this.handleChange(e.target.value, ch);
                      }}
                      defaultValue={this.getDataValue(ch)}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MultipleChoiceSingleLineTextComponent;
