import React from 'react';
import QuestionDetailsComponent from './QuestionDetailsComponent';

class RatingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
    this.clickHandle = this.clickHandle.bind(this);

    this.icons = [
      [],
      [],
      [],
      [
        { icon: 'far fa-angry fa-stack-2x' },
        { icon: 'far fa-meh fa-stack-2x' },
        { icon: 'far fa-grin-beam fa-stack-2x' },
      ],
      [
        { icon: 'far fa-angry fa-stack-2x' },
        { icon: 'far fa-frown fa-stack-2x' },
        { icon: 'far fa-smile fa-stack-2x' },
        { icon: 'far fa-grin-beam fa-stack-2x' },
      ],
      [
        { icon: 'far fa-angry fa-stack-2x' },
        { icon: 'far fa-frown fa-stack-2x' },
        { icon: 'far fa-meh fa-stack-2x' },
        { icon: 'far fa-smile fa-stack-2x' },
        { icon: 'far fa-grin-beam fa-stack-2x' },
      ],
    ];
  }

  isSelected(choiceIndex) {
    const { question, answer } = this.props;
    const selection = answer.value;
    if (question.ratingScaleFromNum === 1) {
      return choiceIndex === selection - 1;
    } else {
      return choiceIndex === selection;
    }
  }

  clickHandle(e, choiceIndex) {
    const { question } = this.props;
    if (question.ratingScaleFromNum === 1) {
      this.props.setAnswer(this.props, choiceIndex + 1);
    } else {
      this.props.setAnswer(this.props, choiceIndex);
    }
  }

  render() {
    const { question } = this.props;
    const choices =
      question.type === 'rating_question'
        ? question.ratingQuestionContent
            .sort((ch1, ch2) =>
              question.ratingDisplayOption === 'STAR' /* if star */
                ? ch2.ratingLabelIndex -
                  ch1.ratingLabelIndex /* sort descending */
                : ch1.ratingLabelIndex - ch2.ratingLabelIndex,
            )
            .map((ch) => ({
              index: ch.ratingLabelIndex,
              choiceText: ch.ratingLabelText,
              iconColor: ch.ratingLabelColor,
            }))
        : question.choices;
    const ratingType =
      question.type === 'rating_question'
        ? question.ratingDisplayOption === 'SMILEY'
          ? 'smiley'
          : question.ratingDisplayOption === 'STAR'
          ? 'star'
          : null
        : question.type === 'nps_question'
        ? 'nps'
        : 'scale';

    return (
      <div
        id={`${question.elementKey}`}
        className={`question rating ${ratingType} total_${choices.length}`}
      >
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <div className="label_wrapper">
            <div className="label">
              <span className="label_content">{question.beginLabel}</span>
            </div>
            <div className="label">
              <span className="label_content">{question.endLabel}</span>
            </div>
          </div>
          <div className="choices">
            <div className="choice_group">
              {/* <h1>Lenght {`${choices.length}`}</h1> */}
              {choices.map((choice) => (
                <div
                  className={`choice index_${choice.index}${
                    this.isSelected(choice.index) ? ' selected' : ''
                  }`}
                  key={choice.index}
                >
                  <input
                    type="radio"
                    id={`${question.elementKey}choice${choice.index}`}
                    checked={this.isSelected(choice.index)}
                    readOnly
                    onClick={(e) => {
                      this.clickHandle(e, choice.index);
                    }}
                  />
                  <label
                    htmlFor={`${question.elementKey}choice${choice.index}`}
                  >
                    {!question.ratingDisplayOption ? (
                      <span className="checkmark" />
                    ) : (
                      ''
                    )}
                    {question.ratingDisplayOption === 'STAR' ? (
                      <span
                        className="fa-stack fa-2x"
                        onClick={(e) => {
                          this.clickHandle(e, choice.index);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {this.isSelected(choice.index) ? (
                          <i
                            className="fas fa-star fa-stack-2x"
                            style={{ color: choice.iconColor }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-star fa-stack-2x"
                            style={{ color: 'white' }}
                          ></i>
                        )}
                        {choice.iconColor ? (
                          ''
                        ) : (
                          <i
                            className="fas fa-star fa-stack-2x"
                            style={{ color: 'white' }}
                          ></i>
                        )}
                        <i
                          className="far fa-star fa-stack-2x"
                          style={{ color: 'black' }}
                        ></i>
                      </span>
                    ) : (
                      ''
                    )}
                    {question.ratingDisplayOption === 'SMILEY' ? (
                      <span
                        className="fa-stack fa-2x"
                        onClick={(e) => {
                          this.clickHandle(e, choice.index);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {this.isSelected(choice.index) ? (
                          <i
                            className="fas fa-circle fa-stack-2x"
                            style={{ color: choice.iconColor }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-circle fa-stack-2x"
                            style={{ color: 'white' }}
                          ></i>
                        )}
                        {choice.iconColor ? (
                          ''
                        ) : (
                          <i
                            className="fas fa-circle fa-stack-2x"
                            style={{ color: 'white' }}
                          ></i>
                        )}
                        <i
                          className={
                            this.icons[choices.length][choice.index].icon
                          }
                          style={{ color: 'black' }}
                        ></i>
                      </span>
                    ) : (
                      ''
                    )}
                    <span
                      className="choice_content"
                      style={{ wordWrap: 'anywhere' }}
                    >
                      {choice.choiceText}
                    </span>
                    <span className="rating_label_content">
                      {choice === choices[0]
                        ? question.beginLabel
                        : choice === choices[choices.length - 1]
                        ? question.endLabel
                        : null}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RatingComponent;
