import React from 'react';
import QuestionDetailsComponent from './QuestionDetailsComponent';

class SingleLineTextComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(e) {
    this.props.setAnswer(this.props, e);
  }

  render() {
    const { question, answer } = this.props;
    const { value } = answer;
    const { elementKey, questionContent } = question;
    const { helpText } = questionContent;
    const maxLength =
      elementKey === 'DFBF6A48-BC4B-4890-B3DA-7AFE2E45D877' ? 10 : undefined;
    return (
      <div id={`${elementKey}`} className={`question text_singleline`}>
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <input
            id={`singleline${elementKey}`}
            type="text"
            defaultValue={value}
            onBlur={(e) => this.handleBlur(e)}
            placeholder={helpText}
            maxLength={maxLength}
          />
        </div>
      </div>
    );
  }
}

export default SingleLineTextComponent;
