import {
  ANSWER_SET_VALUE,
  ANSWER_REMOVE,
  ANSWER_REMOVE_VALUE
} from "../ActionTypes/AnswerType";
import { AnswerSkip } from '../Utility/AnswerValidateState';

const answerReducer = (state = {
  data: {}
}, action) => {
  switch (action.type) {
    case ANSWER_SET_VALUE:
      state = {
        ...state,
        data: { ...state.data,
          [action.key]: action.data
        },
      };
      break;
    case ANSWER_REMOVE_VALUE:
    action.list.forEach(element => {
      delete  state.data[element];
    });

      state = {
        ...state,
        data: {  ...state.data  },
      };
      break;
    case ANSWER_REMOVE:
      if (state.data.hasOwnProperty(action.key)) {
        delete state.data[action.key];
        if (AnswerSkip.value.indexOf(action.key)<0) {
          AnswerSkip.value.push(action.key);
        }
      }
      break;
    default:
  }

  return state;
};

export default answerReducer;
