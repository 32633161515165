import React, { Component } from "react";
import {getPathForApiCall} from "../Utility/ConditionValidator";

class LanguageComponent extends Component {
  render() {
    const { key, surveyID, surveyLanguages, isPublish, currentLanguageID} = this.props.conduct;
    if (!surveyLanguages || surveyLanguages.length === 1) return "";
     const pathForApiCall = getPathForApiCall();
    return (
      <div className="survey_language_wrapper">
        <select className="survey_language" onChange={e => this.props.setLanguages( pathForApiCall, key, surveyID, e.target.value, isPublish)}
          defaultValue={currentLanguageID}
        >
          {surveyLanguages.map((v,i) => {
            return <option key={`lang${i}`} value={v.languageID}>{v.languageName}</option>;
          })}
        </select>
      </div>
    );
  }
}

export default LanguageComponent;
