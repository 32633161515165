import React, { Component } from "react";
import "./IndexComponent.scss";

class IndexComponent extends Component {
  render() {
    return (
      <div className="fb-container-1">
        <img
          src={require("../assets/images/smile-index.gif")}
          className="logo"
          alt="Feedback180 - Turning Insight into Action"
        />
        <div className="fb-title">Easily create, send, and analyze surveys</div>
        <div className="sub-title">
          Use the survey platform that answers questions every day<br />Design
          and send professional surveys fast
        </div>
      </div>
    );
  }
}

export default IndexComponent;
