import { Subject } from 'rxjs';

export class ActionSubscriptionService {
  static actionNextSubject = new Subject();

  static getSubject() {
      return this.actionNextSubject;
  }
  static clickAction() {
      this.actionNextSubject.next(true);
  }
}
