import React, { Component } from "react";
import { isNull, addStyleHeaderObject } from "../Utility/index";

class LogoComponent extends Component {
  componentDidMount() {
    if (!isNull(this.props.conduct.logoUrl)) {
      addStyleHeaderObject(
        {
          [`.logo_wrapper`]: {
            "text-align": this.props.conduct.logoAlignment
          },
          [`.logo`]: {
            "background-image": `url(${this.props.conduct.logoUrl})`,
            width: `${this.props.conduct.logoWidth}px`,
            height: `${this.props.conduct.logoHeight}px`
          }
        },

        "mStyle"
      );
    }
  }
  render() {
    if (
      !isNull(this.props.conduct.logoUrl) &&
      (this.props.conduct.logoUrl !== "")
    ) {
      return (
        <div className="logo_wrapper">
          <div className="logo" />
        </div>
      );
    }
    return "";
  }
}

export default LogoComponent;
