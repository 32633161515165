import React from 'react';
import QuestionDetailsComponent from './QuestionDetailsComponent';

class SingleLinePhoneComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleBlur(e) {
    this.props.setAnswer(this.props, e);
  }

  onlyNumberKey(evt) {
    let pattern = /[0-9]/;
    let ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      if (!pattern.test(evt.key)) {
        evt.preventDefault();
      }
    }

    return true;
  }

  render() {
    const { question, answer } = this.props;
    const { value } = answer;
    const { elementKey, questionContent } = question;
    const { helpText } = questionContent;
    return (
      <div id={`${elementKey}`} className={`question text_singlelinephone`}>
        <QuestionDetailsComponent {...this.props} />
        <div className="question_element">
          <input
            id={`singlelinephone${elementKey}`}
            type="tel"
            defaultValue={value}
            onBlur={(e) => this.handleBlur(e)}
            placeholder={helpText}
            onKeyDown={(e) => this.onlyNumberKey(e)}
          />
        </div>
      </div>
    );
  }
}

export default SingleLinePhoneComponent;
