import React from "react";
import { connect } from "react-redux";
import UrlPattern from "url-pattern";
import {
  selectConductData,
  setIndexPage,
  setConductNode,
} from "../Actions/ConductAction";
import { NOTFOUND, INDEX } from "../Utility/TypeValues";
import { setPathForApiCall } from "../Utility/ConditionValidator";
import SurveyFlowContainer from "./SurveyFlowContainer";
import {
  addStyleHeaderObject,
  appendStyleHeaderText,
  decryptionWithCryptoJS,
  getQRInformation,
  checkValidQRInfo,
  getToken,
} from "../Utility/index";
import { isNullOrUndefined } from "../Utility/index";
import $ from "jquery-slim";

class ConductContainer extends React.Component {
  constructor(props) {
    super(props);
    this.validUrl = false;
    this.paramForApiCall = "";
    this.token = "";
    this.setUrlPropeties();
  }

  componentDidMount() {
    if (this.validUrl && this.paramForApiCall !== "") {
      if (this.paramForApiCall === "/") {
        this.props.setConductNode(INDEX);
      } else {
        this.props.setConduct(this.paramForApiCall, this.token);
      }
    } else {
      this.props.setConductNode(NOTFOUND);
    }
    this.addStyle();
  }

  setUrlPropeties() {
    const pattern = new UrlPattern("/:segment");
    const path = window.location.pathname;

    const matched =
      pattern.match(path) !== null ? pattern.match(path) : { segment: "" };
    const { segment } = matched;

    if (path.length === 0 || path === "/") {
      this.paramForApiCall = "/";
      this.validUrl = true;
    } else if (path.indexOf("/p/") === 0) {
      let extPath = path.substring(3);
      this.paramForApiCall = `draft/${extPath}`;
      this.validUrl = true;
    } else {
      if (segment.length > 0) {
        if (segment.length === 7) {
          // Smart Queue
          this.paramForApiCall = `smartq/${segment}`;
          this.token = "";
          this.validUrl = true;
        } else {
          // QR Survey
          const path = decryptionWithCryptoJS(segment);

          if (path.length > 0) {
            const qrInfo = getQRInformation(path);
            // check valid object
            if (checkValidQRInfo(qrInfo)) {
              this.paramForApiCall = `qrsurvey?${path}`;
              this.token = getToken();
              this.validUrl = true;
            } else {
              this.paramForApiCall = "/";
              this.validUrl = false;
            }
          } else {
            this.paramForApiCall = "/";
            this.validUrl = false;
          }
        }
      } else {
        this.paramForApiCall = "/";
        this.validUrl = false;
      }
    }
    setPathForApiCall(this.paramForApiCall);
  }

  pageSetup() {
    return <SurveyFlowContainer />;
  }
  addStyle() {
    if (!isNullOrUndefined(this.props.conduct.customize)) {
      appendStyleHeaderText(
        "mStyleCustom",
        this.props.conduct.customize.customCss
      );
      if ($(".mStyleNotChange").length === 0) {
        let stylesOuter = {};
        if (!isNullOrUndefined(this.props.conduct.customize.backgroundImage)) {
          stylesOuter = {
            "background-image": `url(${this.props.conduct.customize.backgroundImage})`,
            "background-color": `${this.props.conduct.customize.backgroundColor}`,
          };
        } else if (
          !isNullOrUndefined(this.props.conduct.customize.backgroundColor)
        ) {
          stylesOuter = {
            "background-color": `${this.props.conduct.customize.backgroundColor}`,
          };
        }
        addStyleHeaderObject(
          {
            body: {
              "font-family": this.getFontFamily(
                this.props.conduct.customize.fontName
              ),
            },
            ".error_message": {
              color: this.props.conduct.customize.validateTextAnswer,
            },
            ".cb_container input:checked ~ .checkmark, .rb_container input:checked ~ .checkmark, .cb_rank_container input:checked ~ .checkmark":
              {
                "background-color":
                  this.props.conduct.customize.answerTextColor,
              },

            ".survey": {
              color: this.props.conduct.customize.questionTextColor,
            },
            ".outer": stylesOuter,
            ".survey_button": {
              "background-color":
                this.props.conduct.customize.buttonBackgroundColor,
            },
          },
          "mStyleNotChange"
        );
      }
    }
  }
  getFontFamily(fontName) {
    if (isNullOrUndefined(fontName) || fontName === "") {
      return "sans-serif";
    }
    return fontName;
  }
  render() {
    if (this.props.conduct.hasOwnProperty("id")) {
      this.addStyle();
      return (
        <div className="outer">
          <div className="inner">
            <div className="survey">
              <SurveyFlowContainer />
            </div>
            {this.props.conduct.showPowerBy ? (
              <div className="footer">
                <img
                  src={this.props.conduct.powerByUrl}
                  className="power_by"
                  alt="Powered by Feedback180"
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      );
    }
    return <div className="container">{this.pageSetup()}</div>;
  }
}

const mapStateToProps = (state) => {
  return { conduct: state.conductReducer /*,  app: state.appReducer */ };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConduct: (param, token) => {
      dispatch(selectConductData(param, token));
    },

    setIndexPage: () => {
      dispatch(setIndexPage());
    },
    setConductNode: (node) => {
      dispatch(setConductNode(node));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConductContainer);
