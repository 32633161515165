import React from "react";
import "./NotFoundComponent.scss";

class NotFoundComponent extends React.Component {
  render() {
    return (
      <div className="f404-page">
        <div className="f404-container">
          <div className="f404-content">
            <div className="f404">
              <div className="ocean">
                <img
                  src={require("../assets/images/logo-mainweb.png")}
                  className="fishy"
                  alt="Fishy"
                />
              </div>
              <div className="text">
                <div className="t3">
                  ขออภัยค่ะ
                  <p>ท่านได้ทำการตอบแบบสอบถามแล้ว</p>
                  <p>หรือแบบสอบถามนี้ได้หมดอายุไปแล้ว</p>
                  <p>ขอขอบคุณสำหรับความคิดเห็นของท่านที่จะ</p>
                  <p>ช่วยให้ธนาคารนำไปพัฒนาบริการให้ดียิ่งขึ้นต่อไป</p>
                  <p>
                    <br></br>
                  </p>
                  <p>หากมีข้อสงสัยสามารถติดต่อธนาคารได้ที่</p>
                  <p>02-8888888</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundComponent;
