import React from "react";
import "./NetPromotorScoreComponent.scss";
import RatingScaleComponent from "./RatingScaleComponent"

class NetPromotorScoreComponent extends React.Component {
  render() {
    const { question } = this.props;
    const { startLabelText, endLabelText } = question.npsQuestionContent;
    let ratingScaleProps = { ...this.props }
    ratingScaleProps.question.ratingScaleFromNum = 0;
    ratingScaleProps.question.ratingScaleToNum = 10;
    ratingScaleProps.question.ratingScaleContent = {
      scaleFromLabelText: startLabelText,
      scaleToLabelText: endLabelText
    }
    return <RatingScaleComponent {...ratingScaleProps} />;
  }
}

export default NetPromotorScoreComponent;
