export const urlDefalutApi = process.env.REACT_APP_URL_DEFAULT_API;
export const WELCOME = 'WELCOME';
export const THANKYOU = 'THANKYOU';
export const BACK = 'BACK';
export const FORWARD = 'FORWARD';
export const SINGLELINETEXT = 'singleline_text_question';
export const EMAIL_SINGLELINETEXT = 'email_question';
export const MULTIPLELINETEXT = 'multipleline_text_question';
export const DISPLAYMESSAGE = 'display_message';
export const NUMBER = 'numeric_question';
export const RANKING = 'ranking_question';
export const RATING = 'rating_question';
export const RATINGSCALE = 'rating_scale_question';
export const DATETIME = 'datetime_question';
export const DATE_INPUT = 'date_input_question';
export const DATE_DROPDOWN = 'date_dropdown_question';
export const DROPDOWNLIST = 'dropdown_question';
export const NETPROMOTORSCORE = 'nps_question';
export const MULTIPLECHOICEONE = 'multiple_choice_one_answer_question';
export const MULTIPLECHOICEMULTIPLE =
  'multiple_choice_multiple_answer_question';
export const MULTIPLECHOICESINGLELINETEXT =
  'multiple_choice_singleline_text_question';
export const MATRIXCHOICEONE = 'matrix_choice_one_answer_question';
export const MATRIXCHOICEMULTIPLE = 'matrix_choice_multiple_answer_question';
export const MATRIXRANKING = 'matrix_ranking_question';
export const MATRIXSINGLELINETEXT = 'matrix_singleline_text_question';
export const MATRIXDROPDOWN = 'matrix_dropdown_question';
export const PHONE_SINGLELINETEXT = 'phone_question';
export const RICHTEXT = 'RICHTEXT';
export const NOTFOUND = 'NOTFOUND';
export const EXPIRE = 'EXPIRE';
export const DUPLICATE = 'DUPLICATE';
export const TH = 'th';
export const CH = 'CH';
export const EN = 'en';
export const ERROR_500 = 'ERROR_500';
export const UNAUTHORIZED_CODE = 401;
export const SINGLELINE_RESPONSE = 'SingleLineResponse';
export const MULTIPLELINE_RESPONSE = 'MultipleLineResponse';
export const NUMERIC_RESPONSE = 'NumericResponse';
export const EMAIL_RESPONSE = 'EmailResponse';
export const DATETIME_RESPONSE = 'DateTimeResponse';
export const DROPDOWNLIST_RESPONSE = 'DropdownListResponse';
export const NPS_RESPONSE = 'NPSResponse';
export const RATING_RESPONSE = 'RatingResponse';
export const RATINGSCALE_RESPONSE = 'RatingScaleResponse';
export const MULTIPLECHOICE_ONEANSWER_RESPONSE =
  'MultipleChoiceOneAnswerResponse';
export const MULTIPLECHOICE_MULTIPLEANSWER_RESPONSE =
  'MultipleChoiceMultipleAnswerResponse';
export const RANKING_RESPONSE = 'RankingResponse';
export const MATRIXRANKING_ANSWER_RESPONSE = 'MatrixRankingAnswerResponse';
export const MATRIXCHOICE_ONEANSWER_RESPONSE = 'MatrixChoiceOneAnswerResponse';
export const MATRIXCHOICE_MULTIPLEANSWER_RESPONSE =
  'MatrixChoiceMultipleAnswerResponse';
export const MATRIXSINGLELINE_ANSWER_RESPONSE =
  'MatrixSingleLineAnswerResponse';
export const MULTIPLECHOICE_SIGLELINETEXTANSWER_RESPONSE =
  'MultipleChoiceSingleLineTextResponse';
export const MATRIX_DROPDOWN_RESPONSE = 'MatrixDropdownAnswerResponse';
export const PHONE_RESPONSE = 'PhoneResponse';
export const INDEX = 'INDEX';
export const LOADING = 'LOADING';
export const ERROR_401 = 'NOTFOUND';
export const PAGE = 'PAGE';
export const GROUP = 'GROUPING';
export const QUESTION = 'QUESTION';
export const FONT1 = 'FONT-1';
export const FONT2 = 'FONT-2';
export const FONT3 = 'FONT-3';
export const RABBIT_USER = 3;
export const validateMessageText = 'validateMessageText';
export const verifyMessageText = 'verifyMessageText';
export const minimumMessageText = 'verifyMinimumValueText';
export const maximumMessageText = 'verifyMaximumValueText';
export const verifyOtherMessageText = 'verifyOtherMessageText';
export const keyAes = 'cmFuZG9t';
