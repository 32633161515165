import { setWrapper } from "../Actions/AnswerAction";
import {
  DISPLAYMESSAGE,
  RANKING,
  WELCOME,
  THANKYOU,
  MULTIPLECHOICEONE,
  MULTIPLECHOICEMULTIPLE,
  MATRIXCHOICEONE,
  MATRIXCHOICEMULTIPLE,
  MATRIXRANKING,
  MATRIXSINGLELINETEXT,
  MULTIPLECHOICESINGLELINETEXT,
  MATRIXDROPDOWN,
  RATING,
  NUMBER,
  validateMessageText,
  verifyMessageText,
  minimumMessageText,
  maximumMessageText,
  verifyOtherMessageText,
  PHONE_SINGLELINETEXT,
  EMAIL_SINGLELINETEXT,
  NETPROMOTORSCORE,
  RATINGSCALE,
} from "./TypeValues";
import Exp from "../Expression/expression";
import {
  isNullOrUndefined,
  isArray,
  isString,
  isNumber,
} from "../Utility/index";
import { validateConditionToRoot } from "./ConditionValidator";
import { findQuestionInCurrentNode } from "./ConditionValidator";
import { removeAnswerValueAny } from "../Utility/ConditionValidator";
const checkSelectedNumberOfChoiceSinglelineText = (d) => {
  return d.filter((e) => e.choiceText.trim() !== "").length;
};
const checkSelectedNumberOfChoice = (d) => {
  return d.filter((e) => e.checkState === true).length;
};
const checkSelectedNumberOfChoiceAndInclude = (d) => {
  let data = d.value.filter((e) => e.checkState === true).length;
  if (d.isIncludeOther === true) data++;
  return data;
};
const setDispatch = (
  dispatch,
  questionElementKey,
  value,
  error,
  isIncludeOther = null,
  IncludeOtherText = null
) => {
  let data = {
    value: value,
    error: error,
  };
  if (IncludeOtherText) {
    data["IncludeOtherText"] = IncludeOtherText;
  }
  if (!isNullOrUndefined(isIncludeOther)) {
    data["isIncludeOther"] = isIncludeOther;
  }
  dispatch(setWrapper(questionElementKey, data));
  removeAnswerValueAny();
};

const isSelectedAppendTextBoxIsEmpty = (question, answer) => {
  const appendTextList = question.choices.reduce((acc, v, i, arr) => {
    acc[v.elementKey] = v.appendText;
    return acc;
  }, []);
  return answer.some(
    (v) =>
      v.checkState === true &&
      appendTextList[v.elementKey] === true &&
      v.choiceText === ""
  );
};

export const validateRequire = (props, event, dispatch) => {
  const { question, answer } = props;
  //get from element in component
  let value = event.target.value.trim();
  event.target.value = value;
  if (question.mandatory && value === "") {
    setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText
    return;
  }
  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (
      question.verifyRegularExpression &&
      !new RegExp(question.verifyRegularExpression).test(value)
    ) {
      setDispatch(dispatch, question.elementKey, value, answer.error); //verifyMessageText

      return;
    } else {
      setDispatch(dispatch, question.elementKey, value, answer.error); //""
    }
  } else {
    setDispatch(dispatch, question.elementKey, value, answer.error); //""
  }
};

export const validateNumberRequire = (props, event, dispatch) => {
  const { question, answer } = props;
  let value = Number.parseFloat(event.target.value);
  if (question.mandatory && isNaN(value)) {
    value = "";
    setDispatch(dispatch, question.elementKey, value, answer.error);
    return;
  }
  if (!isNaN(value) && question.maximumValue < value) {
    setDispatch(dispatch, question.elementKey, value, answer.error);
    return;
  } else if (!isNaN(value) && question.minimumValue > value) {
    setDispatch(dispatch, question.elementKey, value, answer.error);
    return;
  } else {
    //edit if value is nan
    if (isNaN(value)) {
      value = "";
    }
    setDispatch(dispatch, question.elementKey, value, answer.error);
  }
};
export const validateRequireSetValue = (props, value, dispatch) => {
  const { question, answer } = props;
  if (question.mandatory && value === "") {
    setDispatch(dispatch, question.elementKey, value, answer.error); //verifyMessageText
    return;
  } else {
    setDispatch(dispatch, question.elementKey, value, answer.error); //""
  }
  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(dispatch, question.elementKey, value, answer.error); //verifyMessageText
      return;
    } else {
      setDispatch(dispatch, question.elementKey, value, answer.error); //
    }
  }
};
export const validateRequireSetValueArrayMatrixAuto = (
  props,
  question,
  value,
  dispatch,
  singleLine = false
) => {
  let objectElememtKey = [];
  let logValue = [];
  if (Array.isArray(value)) {
    logValue = value.reduce((acc, v, i, arr) => {
      if (objectElememtKey.indexOf(v.questionElementKey) < 0) {
        objectElememtKey.push(v.questionElementKey);

        let data = arr.some((value, index) => {
          if (singleLine) {
            return (
              value.questionElementKey === v.questionElementKey &&
              !isNullOrUndefined(value.value) &&
              value.value.trim() !== ""
            );
          }
          return (
            value.questionElementKey === v.questionElementKey &&
            value.checkState
          );
        });
        acc.push(data);
      }
      return acc;
    }, []);
  }
  if (
    question.mandatory &&
    (logValue.indexOf(false) >= 0 || logValue.length === 0)
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }
  return true;
};
export const validateRequireSetValueArrayMatrixRankingAuto = (
  props,
  question,
  value,
  dispatch
) => {
  let choiceElementKeys = [];
  let logValue = [];
  if (Array.isArray(value)) {
    if (
      question.matrixChoices.length === 0 ||
      question.matrixQuestionItems.length === 0
    ) {
      setDispatch(dispatch, question.elementKey, value, "");
      return true;
    } else if (
      question.matrixChoices.length === question.matrixQuestionItems.length
    ) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.choiceElementKey) < 0) {
          choiceElementKeys.push(v.choiceElementKey);

          let data = arr.some((value, index) => {
            return (
              value.choiceElementKey === v.choiceElementKey && value.checkState
            );
          });
          acc.push(data);
        }
        return acc;
      }, []);
    } else if (
      question.matrixChoices.length > question.matrixQuestionItems.length
    ) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.questionElementKey) < 0) {
          choiceElementKeys.push(v.questionElementKey);

          let data = arr.some((value, index) => {
            return (
              value.questionElementKey === v.questionElementKey &&
              value.checkState
            );
          });
          acc.push(data);
        }
        return acc;
      }, []);
    } else if (
      question.matrixChoices.length < question.matrixQuestionItems.length
    ) {
      logValue = value.reduce((acc, v, i, arr) => {
        if (choiceElementKeys.indexOf(v.choiceElementKey) < 0) {
          choiceElementKeys.push(v.choiceElementKey);

          let data = arr.some((value, index) => {
            return (
              value.choiceElementKey === v.choiceElementKey && value.checkState
            );
          });
          acc.push(data);
        }
        return acc;
      }, []);
    }
  }
  if (
    question.mandatory &&
    (logValue.indexOf(false) >= 0 || logValue.length === 0)
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }
  return true;
};
export const validateRequireSetValueArrayMatrix = (
  props,
  value,
  dispatch,
  singleLine = false
) => {
  const { question, answer } = props;
  let objectElememtKey = [];
  let logValue = [];
  if (Array.isArray(value)) {
    logValue = value.reduce((acc, v, i, arr) => {
      if (objectElememtKey.indexOf(v.questionElementKey) < 0) {
        objectElememtKey.push(v.questionElementKey);

        let data = arr.some((value, index) => {
          if (singleLine) {
            return (
              value.questionElementKey === v.questionElementKey &&
              !isNullOrUndefined(value.value) &&
              value.value.trim() !== ""
            );
          }
          return (
            value.questionElementKey === v.questionElementKey &&
            value.checkState
          );
        });
        acc.push(data);
      }
      return acc;
    }, []);
  }
  if (
    question.mandatory &&
    Array.isArray(value) &&
    logValue.indexOf(false) >= 0
  ) {
    setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText

    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, answer.error); //""
  }
};
export const validateRequireSetValueSingleLineMatrix = (
  props,
  value,
  dispatch
) => {
  const { question } = props;
  let objectElememtKey = [];
  let logValue = [];
  if (Array.isArray(value)) {
    value.filter((v, i) => {
      if (objectElememtKey.indexOf(v.questionElementKey) < 0) {
        objectElememtKey.push(v.questionElementKey);
        logValue.push(
          value.some((vi, index) => {
            return (
              vi.questionElementKey === v.questionElementKey &&
              vi.value.length > 0
            );
          })
        );
      }
      return true;
    });
  }
  if (
    question.mandatory &&
    Array.isArray(value) &&
    logValue.indexOf(false) >= 0
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }
};
export const validateRequireSetValueArray = (props, value, dispatch) => {
  const { answer, question } = props;
  if (!Array.isArray(value) || (Array.isArray(value) && value.length === 0)) {
    setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, answer.error); //""
  }

  return true;
};
export const validateRequireSetValueMultipleChoiceMultiple = (
  props,
  value,
  dispatch
) => {
  const { question, answer } = props;
  if (question.includeOther === false) {
    if (question.mandatory === false) {
      setDispatch(dispatch, question.elementKey, value, answer.error); //""
      return true;
    } else if (
      question.mandatory === true &&
      Array.isArray(value) &&
      value.length > 0 &&
      question.requireNumOfChoice <= checkSelectedNumberOfChoice(value) &&
      Object.values(value).some((v) => v.checkState === true)
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText

        return false;
      }
      setDispatch(dispatch, question.elementKey, value, answer.error); //""
      return true;
    } else {
      setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText
      return false;
    }
  } else {
    if (question.mandatory === false) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        answer.error, //"",
        value.isIncludeOther,
        value.IncludeOtherText
      );
      return true;
    } else if (
      question.mandatory === true &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      question.requireNumOfChoice <=
        checkSelectedNumberOfChoiceAndInclude(value) &&
      ((Object.values(value.value).some((v) => v.checkState === true) &&
        value.isIncludeOther === true &&
        !isNullOrUndefined(value.IncludeOtherText) &&
        value.IncludeOtherText !== "") ||
        (Object.values(value.value).some((v) => v.checkState === true) &&
          value.isIncludeOther === false) ||
        (Object.values(value.value).some((v) => v.checkState === false) &&
          value.isIncludeOther === true &&
          !isNullOrUndefined(value.IncludeOtherText) &&
          value.IncludeOtherText !== ""))
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          answer.error, //verifyOtherMessageText
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        answer.error, //"",
        value.isIncludeOther,
        value.IncludeOtherText
      );
      return true;
    } else {
      setDispatch(
        dispatch,
        question.elementKey,
        value.hasOwnProperty("value") ? value.value : [],
        answer.error, //validateMessageText,
        value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
        value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
      );
      return false;
    }
  }
};
export const validateRequireSetValueMultipleChoiceOne = (
  props,
  value,
  dispatch
) => {
  const { question, answer } = props;
  if (question.includeOther === false) {
    if (question.mandatory === false) {
      setDispatch(
        dispatch,
        question.elementKey,
        value,
        answer.error //""
      );

      return true;
    } else if (
      question.mandatory &&
      Array.isArray(value) &&
      value.length > 0 &&
      value.some((v) => v.checkState === true)
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        setDispatch(dispatch, question.elementKey, value, answer.error); //verifyOtherMessageText
        return false;
      }
      setDispatch(dispatch, question.elementKey, value, answer.error); //""
      return true;
    } else {
      setDispatch(dispatch, question.elementKey, value, answer.error); //validateMessageText
      return false;
    }
  } else {
    if (question.mandatory === false) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        answer.error, //"",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      question.mandatory &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      (Object.values(value.value).some((v) => v.checkState === true) ||
        (value.isIncludeOther === true &&
          !isNullOrUndefined(value.IncludeOtherText) &&
          value.IncludeOtherText !== ""))
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          answer.error,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        //validateMessageText
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        answer.error, //""
        value.isIncludeOther,
        value.IncludeOtherText
      );
      return true;
    } else {
      setDispatch(
        dispatch,
        question.elementKey,
        value.hasOwnProperty("value") ? value.value : [],
        answer.error, //validateMessageText
        value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
        value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
      );
      return false;
    }
  }
};
export const validateRequireSetValueMultipleChoiceMultipleAuto = (
  props,
  question,
  value,
  dispatch
) => {
  if (question.includeOther === false) {
    if (question.mandatory === false) {
      setDispatch(dispatch, question.elementKey, value, ""); //""
      return true;
    } else if (
      question.mandatory === true &&
      Array.isArray(value) &&
      value.length > 0 &&
      question.requireNumOfChoice <= checkSelectedNumberOfChoice(value) &&
      value.some((v) => v.checkState === true)
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        setDispatch(dispatch, question.elementKey, value, validateMessageText);

        return false;
      }
      setDispatch(dispatch, question.elementKey, value, "");

      return true;
    } else {
      setDispatch(dispatch, question.elementKey, value, validateMessageText);

      return false;
    }
  } else {
    if (
      question.mandatory === false &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.isIncludeOther === true
    ) {
      if (
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          !new RegExp(question.verifyOtherRegularExpression).test(
            value.IncludeOtherText
          )) ||
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          isNullOrUndefined(value.IncludeOtherText))
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }

      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      question.mandatory === false &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.isIncludeOther === false
    ) {
      // fix bug not mandatory and uncheck can go to next question 8/23/2018
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );
      return true;
    } else if (
      question.mandatory === true &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.isIncludeOther === true
    ) {
      if (
        value.value.length > 0 &&
        question.requireNumOfChoice >
          checkSelectedNumberOfChoiceAndInclude(value)
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          validateMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      if (
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          !new RegExp(question.verifyOtherRegularExpression).test(
            value.IncludeOtherText
          )) ||
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          isNullOrUndefined(value.IncludeOtherText))
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );
      return true;
    } else if (
      question.mandatory === true &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      question.requireNumOfChoice <=
        checkSelectedNumberOfChoiceAndInclude(value) &&
      ((Object.values(value.value).some((v) => v.checkState === true) &&
        value.isIncludeOther === true &&
        !isNullOrUndefined(value.IncludeOtherText) &&
        value.IncludeOtherText !== "") ||
        (Object.values(value.value).some((v) => v.checkState === true) &&
          value.isIncludeOther === false) ||
        (Object.values(value.value).some((v) => v.checkState === false) &&
          value.isIncludeOther === true &&
          !isNullOrUndefined(value.IncludeOtherText) &&
          value.IncludeOtherText !== ""))
    ) {
      if (
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          value.isIncludeOther === true &&
          !new RegExp(question.verifyOtherRegularExpression).test(
            value.IncludeOtherText
          )) ||
        (!isNullOrUndefined(question.verifyOtherRegularExpression) &&
          isNullOrUndefined(value.IncludeOtherText) &&
          value.isIncludeOther === true)
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );

        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else {
      if (
        !isNullOrUndefined(question.verifyOtherRegularExpression) &&
        isNullOrUndefined(value.IncludeOtherText) &&
        value.isIncludeOther === true
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          validateMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      if (
        !isNullOrUndefined(question.verifyOtherRegularExpression) &&
        value.isIncludeOther === true &&
        !new RegExp(question.verifyOtherRegularExpression).test(
          value.IncludeOtherText
        )
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.hasOwnProperty("value") ? value.value : [],
        validateMessageText,
        value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
        value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
      );

      return false;
    }
  }
};
export const validateRequireSetValueMultipleChoiceSinglelineTextAuto = (
  props,
  question,
  value,
  dispatch
) => {
  if (question.mandatory === false) {
    setDispatch(dispatch, question.elementKey, value, "");
    return true;
  } else if (
    question.mandatory === true &&
    Array.isArray(value) &&
    value.length > 0 &&
    question.requiredMinimumAnswer <=
      checkSelectedNumberOfChoiceSinglelineText(value)
  ) {
    setDispatch(dispatch, question.elementKey, value, "");

    return true;
  } else {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);

    return false;
  }
};

export const validateRequireSetValueMultipleChoiceOneAuto = (
  props,
  question,
  value,
  dispatch
) => {
  if (question.includeOther === false) {
    if (question.mandatory === false) {
      setDispatch(dispatch, question.elementKey, value, "");
      return true;
    } else if (
      question.mandatory === true &&
      Array.isArray(value) &&
      value.length > 0 &&
      Object.values(value).some((v) => v.checkState === true)
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value)) {
        setDispatch(dispatch, question.elementKey, value, validateMessageText);

        return false;
      }
      setDispatch(dispatch, question.elementKey, value, "");
      return true;
    } else {
      setDispatch(dispatch, question.elementKey, value, validateMessageText);
      return false;
    }
  } else {
    if (
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.isIncludeOther === true
    ) {
      if (
        !isNullOrUndefined(question.verifyOtherRegularExpression) &&
        isNullOrUndefined(value.IncludeOtherText)
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      if (
        !isNullOrUndefined(question.verifyOtherRegularExpression) &&
        !new RegExp(question.verifyOtherRegularExpression).test(
          value.IncludeOtherText
        )
      ) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      question.mandatory === false &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      Object.values(value.value).some((v) => v.checkState === true) &&
      value.isIncludeOther === false
    ) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      question.mandatory &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      (Object.values(value.value).some((v) => v.checkState === true) ||
        (value.isIncludeOther === true &&
          !isNullOrUndefined(value.IncludeOtherText) &&
          value.IncludeOtherText !== ""))
    ) {
      if (isSelectedAppendTextBoxIsEmpty(question, value.value)) {
        setDispatch(
          dispatch,
          question.elementKey,
          value.hasOwnProperty("value") ? value.value : [],
          verifyOtherMessageText,
          value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
          value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
        );
        return false;
      }
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      question.mandatory === false &&
      !isNullOrUndefined(value) &&
      Array.isArray(value.value) &&
      value.value.length > 0 &&
      (Object.values(value.value).some((v) => v.checkState === true) ||
        (value.isIncludeOther === true &&
          !isNullOrUndefined(value.IncludeOtherText) &&
          value.IncludeOtherText !== ""))
    ) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else if (
      isNullOrUndefined(question.verifyOtherRegularExpression) &&
      question.mandatory === false
    ) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.value,
        "",
        value.isIncludeOther,
        value.IncludeOtherText
      );

      return true;
    } else {
      setDispatch(
        dispatch,
        question.elementKey,
        value.hasOwnProperty("value") ? value.value : [],
        validateMessageText,
        value.hasOwnProperty("isIncludeOther") ? value.isIncludeOther : false,
        value.hasOwnProperty("IncludeOtherText") ? value.IncludeOtherText : ""
      );
      return false;
    }
  }
};

export const validateRequireSetValueArrayAuto = (
  props,
  question,
  value,
  dispatch
) => {
  // const { answer } = props;
  if (
    question.includeOther &&
    Array.isArray(value) &&
    value.length > 0 &&
    value[value.length - 1].checkState &&
    isNullOrUndefined(value[value.length - 1].choiceText) &&
    value[value.length - 1].choiceText !== ""
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);

    return false;
  }
  if (
    question.mandatory &&
    Array.isArray(value) &&
    !Object.values(value).some((v) => {
      return v.checkState === true;
    })
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }

  return true;
};
export const validateRequireRatingScaleAuto = (
  props,
  value,
  dispatch,
  question
) => {
  if (question.mandatory && value === "") {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );

    return false;
  }
  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }

  if (isString(value)) {
    setDispatch(dispatch, question.elementKey, value.trim(), "");
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }

  return true;
};
export const validateRequireAuto = (props, value, dispatch, question) => {
  if (question.mandatory && value.trim() === "") {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );

    return false;
  }
  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );
      return false;
    }
  }
  if (isString(value)) {
    setDispatch(dispatch, question.elementKey, value.trim(), "");
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }

  return true;
};
export const validateRequireNpsAuto = (props, value, dispatch, question) => {
  if (question.mandatory && !value) {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );
    return false;
  }

  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(dispatch, question.elementKey, value, verifyMessageText);

      return false;
    }
  }
  setDispatch(dispatch, question.elementKey, value, "");
  return true;
};
export const validateRequireEmailAuto = (props, value, dispatch, question) => {
  if (question.mandatory && value.trim() === "") {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );

    return false;
  }
  if (
    question.mandatory === false &&
    value.trim() !== "" &&
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }
  if (
    question.mandatory &&
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }
  setDispatch(dispatch, question.elementKey, value, "");

  return true;
};
export const validateRequirePhoneAuto = (props, value, dispatch, question) => {
  if (question.mandatory && value.trim() === "") {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );

    return false;
  }
  if (
    question.mandatory === false &&
    value.trim() !== "" &&
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }
  if (
    question.mandatory &&
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }
  setDispatch(dispatch, question.elementKey, value, "");

  return true;
};
export const validateRequireRatingAuto = (props, value, dispatch, question) => {
  if (question.mandatory && !isNumber(value) && value.trim() === "") {
    setDispatch(
      dispatch,
      question.elementKey,
      value.trim(),
      validateMessageText
    );

    return false;
  }
  if (
    !isNullOrUndefined(question.verifyRegularExpression) &&
    question.verifyRegularExpression !== "" &&
    !isNullOrUndefined(value)
  ) {
    if (!new RegExp(question.verifyRegularExpression).test(value)) {
      setDispatch(
        dispatch,
        question.elementKey,
        value.trim(),
        verifyMessageText
      );

      return false;
    }
  }

  if (isString(value)) {
    setDispatch(dispatch, question.elementKey, value.trim(), "");
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }
  return true;
};

export const validateNumberRequireAuto = (props, value, dispatch, question) => {
  value = Number.parseFloat(value);
  if (question.mandatory && isNaN(value)) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);
    return false;
  }
  if (!isNaN(value) && question.maximumValue < value) {
    setDispatch(dispatch, question.elementKey, value, maximumMessageText);
    return false;
  } else if (!isNaN(value) && question.minimumValue > value) {
    setDispatch(dispatch, question.elementKey, value, minimumMessageText);
    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }

  return true;
};
export const validateRankingRequireAuto = (
  props,
  value,
  dispatch,
  question
) => {
  if (
    question.mandatory &&
    !isNumber(value) &&
    isArray(value) &&
    value.length === 0
  ) {
    setDispatch(dispatch, question.elementKey, value, validateMessageText);

    return false;
  } else {
    setDispatch(dispatch, question.elementKey, value, "");
  }
  return true;
};
export const dataPassValidate = (props, dispatch) => {
  const { conduct, answers } = props;
  if ([WELCOME, THANKYOU].indexOf(conduct.node) >= 0) {
    return true;
  }
  let checkValidate = [];
  const context = Exp.survey_conduct_context(
    conduct.questions,
    answers,
    conduct.contextVariables
  );
  let copyNodes = findQuestionInCurrentNode(
    props.nodes,
    conduct.questions,
    answers,
    context
  );

  copyNodes.forEach((node, index) => {
    if (!validateConditionToRoot(node, context)) {
      return true;
    }
    let question = conduct.questions.filter((q, i) => {
      return q.elementKey === node.elementKey;
    });
    if (question[0]) {
      question = question[0];
      if (question.type === DISPLAYMESSAGE) {
        return true;
      }

      let answersKeys = Object.keys(props.answers);

      if (question && answersKeys.indexOf(question.elementKey) >= 0) {
        if (MULTIPLECHOICEONE === question.type) {
          let answerValues = props.answers[question.elementKey];
          if (!question.includeOther) {
            answerValues = props.answers[question.elementKey].value;
          }
          if (
            !validateRequireSetValueMultipleChoiceOneAuto(
              props,
              question,
              answerValues,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MULTIPLECHOICEMULTIPLE === question.type) {
          let answerValues = props.answers[question.elementKey];
          if (!question.includeOther) {
            answerValues = props.answers[question.elementKey].value;
          }
          if (
            !validateRequireSetValueMultipleChoiceMultipleAuto(
              props,
              question,
              answerValues,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MULTIPLECHOICESINGLELINETEXT === question.type) {
          let answerValues = props.answers[question.elementKey];
          if (!question.includeOther) {
            answerValues = props.answers[question.elementKey].value;
          }
          if (
            !validateRequireSetValueMultipleChoiceSinglelineTextAuto(
              props,
              question,
              answerValues,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (
          [
            MATRIXCHOICEMULTIPLE,
            MATRIXCHOICEONE,
            MATRIXSINGLELINETEXT,
            MATRIXDROPDOWN,
          ].indexOf(question.type) >= 0
        ) {
          if (
            !validateRequireSetValueArrayMatrixAuto(
              props,
              question,
              props.answers[question.elementKey].value,
              dispatch,
              question.type === MATRIXSINGLELINETEXT ||
                question.type === MATRIXDROPDOWN
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MATRIXRANKING === question.type) {
          if (
            !validateRequireSetValueArrayMatrixRankingAuto(
              props,
              question,
              props.answers[question.elementKey].value,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (RANKING === question.type) {
          if (
            !validateRankingRequireAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (NUMBER === question.type) {
          if (
            !validateNumberRequireAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (PHONE_SINGLELINETEXT === question.type) {
          if (
            !validateRequirePhoneAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (EMAIL_SINGLELINETEXT === question.type) {
          if (
            !validateRequireEmailAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (RATING === question.type) {
          if (
            !validateRequireRatingAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (RATINGSCALE === question.type) {
          if (
            !validateRequireRatingScaleAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else if (NETPROMOTORSCORE === question.type) {
          if (
            !validateRequireNpsAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        } else {
          if (
            !validateRequireAuto(
              props,
              props.answers[question.elementKey].value,
              dispatch,
              question
            )
          ) {
            checkValidate.push(false);
          }
        }
      } else if (question && answersKeys.indexOf(question.elementKey) < 0) {
        if (MULTIPLECHOICEONE === question.type) {
          let data = [];
          if (question.includeOther) {
            data = {
              isIncludeOther: false,
              IncludeOtherText: null,
              value: [],
            };
          }
          if (
            !validateRequireSetValueMultipleChoiceOneAuto(
              props,
              question,
              data,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MULTIPLECHOICEMULTIPLE === question.type) {
          let data = [];
          if (question.includeOther) {
            data = {
              isIncludeOther: false,
              IncludeOtherText: null,
              value: [],
            };
          }
          if (
            !validateRequireSetValueMultipleChoiceMultipleAuto(
              props,
              question,
              data,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MULTIPLECHOICESINGLELINETEXT === question.type) {
          let data = [];
          if (question.includeOther) {
            data = {
              isIncludeOther: false,
              IncludeOtherText: null,
              value: [],
            };
          }
          if (
            !validateRequireSetValueMultipleChoiceSinglelineTextAuto(
              props,
              question,
              data,
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (
          [
            MATRIXCHOICEMULTIPLE,
            MATRIXCHOICEONE,
            MATRIXSINGLELINETEXT,
            MATRIXDROPDOWN,
          ].indexOf(question.type) >= 0
        ) {
          if (
            !validateRequireSetValueArrayMatrixAuto(
              props,
              question,
              [],
              dispatch,
              question.type === MATRIXSINGLELINETEXT ||
                question.type === MATRIXDROPDOWN
            )
          ) {
            checkValidate.push(false);
          }
        } else if (MATRIXRANKING === question.type) {
          if (
            !validateRequireSetValueArrayMatrixRankingAuto(
              props,
              question,
              [],
              dispatch
            )
          ) {
            checkValidate.push(false);
          }
        } else if (RANKING === question.type) {
          if (!validateRankingRequireAuto(props, [], dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (NUMBER === question.type) {
          if (!validateNumberRequireAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (PHONE_SINGLELINETEXT === question.type) {
          if (!validateRequirePhoneAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (EMAIL_SINGLELINETEXT === question.type) {
          if (!validateRequireEmailAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (RATING === question.type) {
          if (!validateRequireRatingAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (RATINGSCALE === question.type) {
          if (!validateRequireRatingScaleAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else if (NETPROMOTORSCORE === question.type) {
          if (!validateRequireNpsAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        } else {
          if (!validateRequireAuto(props, "", dispatch, question)) {
            checkValidate.push(false);
          }
        }
      }
    }
  });
  if (
    // check validate
    checkValidate.some((v) => {
      return v === false;
    })
  ) {
    //return funtcion
    return false;
  }
  //return funtcion
  return true;
};
