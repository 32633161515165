import React, { Component } from 'react';
import { connect } from 'react-redux';
import NodeContainer from './NodeContainer';
import ButtonContainer from './ButtonContainer';
import { NOTFOUND, ERROR_500, LOADING } from '../Utility/TypeValues';
import Exp from '../Expression/expression';
import { isNull } from '../Utility/index';
import LanguageComponent from '../Components/LanguageComponent';
import { validateConditionToRoot } from '../Utility/ConditionValidator';

class QuestionContainer extends Component {
  render() {
    const { conduct, answers, nodes } = this.props;

    if (nodes[0]) {
      let context = Exp.survey_conduct_context(
        conduct.questions,
        answers,
        conduct.contextVariables,
      );

      if (!validateConditionToRoot(nodes[0], context)) {
        return '';
      }
    }

    return (
      <div
        className="page"
        id={`${this.props.nodes[0] ? this.props.nodes[0].elementKey : ''}`}
      >
        <LanguageComponent conduct={this.props.conduct} />
        <div className="page_body">
          {isNull(nodes) ? (
            ''
          ) : (
            <NodeContainer
              key={`questionnode${nodes[0].elementKey}`}
              nodes={nodes}
            />
          )}

          {[LOADING, ERROR_500, NOTFOUND].indexOf(conduct.node) < 0 ? (
            <ButtonContainer
              key={`questionbtn${nodes[0].elementKey}`}
              nodes={nodes}
              buttonNext={conduct.surveyContent.buttonNext}
              buttonPrevious={conduct.surveyContent.buttonPrevious}
            />
          ) : (
            '์'
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    conduct: state.conductReducer,
    answers: state.answerReducer.data,
  };
};

export default connect(mapStateToProps)(QuestionContainer);
