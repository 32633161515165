import {
  STACK_ACTION_LOG,
  SET_API_URL,
  ANSWER_NOT_SAVE,
  APP_SET_PROGRESS
} from "../ActionTypes/AppType";

export const setStackWalkStep = (isPop =false,data) => {
  let defaultData = {
    type: STACK_ACTION_LOG,
    data: data
  };
  if(isPop){
    defaultData = {
      type: STACK_ACTION_LOG,
      isPop: isPop
    }
  }
  return defaultData;
};
export const setAnswerNotSave = (data) => {
  return {
    type: ANSWER_NOT_SAVE,
    notsave: data,
  };
};
export const setApiUrl = (url = "/") =>{
 return  {
    type: SET_API_URL,
    url: url
  }
}
export const setProgress = data => {
  return {
    type: APP_SET_PROGRESS,
    progress: data
  };

}
